import {throttle} from 'lodash-es'
const MutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver

const hasObserver = MutationObserver !== undefined

const watermark = {}
watermark.marks = []

const defaultSettings = {
  watermark_id: 'wm_div_id',          // 水印总体的id
  watermark_prefix: 'mask_div_id',    // 小水印的id前缀
  watermark_txt: '测试水印',             // 水印的内容
  watermark_x: 20,                     // 水印起始位置x轴坐标
  watermark_y: 20,                     // 水印起始位置Y轴坐标
  watermark_rows: 0,                   // 水印行数
  watermark_cols: 0,                   // 水印列数
  watermark_x_space: 50,              // 水印x轴间隔
  watermark_y_space: 50,               // 水印y轴间隔
  watermark_font: '微软雅黑',           // 水印字体
  watermark_color: 'black',            // 水印字体颜色
  watermark_fontsize: '18px',          // 水印字体大小
  watermark_alpha: 0.15,               // 水印透明度，要求设置在大于等于0.005
  watermark_width: 100,                // 水印宽度
  watermark_height: 100,               // 水印长度
  watermark_angle: 15,                 // 水印倾斜度数
  throttleTime: 300,                  // 节流时间
  watermark_parent_width: 0,      // 水印的总体宽度（默认值：body的scrollWidth和clientWidth的较大值）
  watermark_parent_height: 0,     // 水印的总体高度（默认值：body的scrollHeight和clientHeight的较大值）
  watermark_parent_node: 'body',     // 水印插件挂载的父元素element,不输入则默认挂在body上
  monitor: true                   // monitor 是否监控， true: 不可删除水印; false: 可删水印。
}
// 判断一个值是不是dom
const isDOM = obj => {
  return (typeof HTMLElement === 'object') ?
    obj instanceof HTMLElement
    : obj &&  typeof obj === 'object' && obj.nodeType === 1 &&  typeof obj.nodeName === 'string'
}
// 判断dom中是否包含某个dom元素
function getTargetNode(ele, target) {
  // ele是内部元素，target是你想找到的包裹元素
  if (!ele || ele === document) return false
  return ele === target ? true : getTargetNode(ele.parentNode, target)
}

// 添加mark 监听 onload 和 resize
watermark.add = conf => {
  const mergeConf = {...defaultSettings, ...conf}
  mergeConf.recordOldValue = {}

  const haveMark = watermark.marks.some(item => item.watermark_id === conf.watermark_id)
  if (!haveMark) {
    watermark.marks.push(mergeConf)
  }
  // window.addEventListener('resize', function() {
  //   loadMark(mergeConf)
  // })
  addListener(mergeConf)
  loadMark(mergeConf)
}

const addListener = mark => {
  // 监听dom是否被移除或者改变属性的回调函数
  mark.domChangeCallback = throttle(records => {
    // 判断是否是删除了水印dom
    const flag = watermark.marks.map(item => {
      return records.some(record => {
        return getTargetNode(record.target, document.getElementById(item.watermark_id))
      })
    }).some(item => item)
    if (flag) {
      loadMark(mark)
      return
    }
    if (records.length === 1 && records[0].removedNodes.length >= 1) {
      const [removeMark] = watermark.marks.filter(item => item.watermark_id === records[0].removedNodes[0].id)
      if (removeMark) {
        loadMark(removeMark)
      }
      loadMark(mark)
      return
    }

    // 监听父节点的尺寸是否发生了变化, 如果发生改变, 则进行重新绘制
    if (!isDOM(mark.watermark_parent_element)) {
      mark.watermark_parent_element = document.getElementById(mark.watermark_parent_node)
    }

    if (mark.watermark_parent_element) {
      const newWidth = getComputedStyle(mark.watermark_parent_element).getPropertyValue('width')
      const newHeight = getComputedStyle(mark.watermark_parent_element).getPropertyValue('height')
      if (newWidth !== mark.recordOldValue.width || newHeight !== mark.recordOldValue.height) {
        mark.recordOldValue.width = newWidth
        mark.recordOldValue.height = newHeight
        loadMark(mark)
      }
    }
  }, defaultSettings.throttleTime)
}

/* 加载水印*/
const loadMark = function(settings) {
  /* 如果元素存在则移除*/
  const watermark_element = document.getElementById(settings.watermark_id)
  watermark_element && watermark_element.parentNode && watermark_element.parentNode.removeChild(watermark_element)

  /* 如果设置水印挂载的父元素的id*/
  settings.watermark_parent_element = document.getElementById(settings.watermark_parent_node)
  const watermark_hook_element = settings.watermark_parent_element

  /* 获取页面宽度*/
  // var page_width = Math.max(watermark_hook_element.scrollWidth,watermark_hook_element.clientWidth) - settings.watermark_width/2;
  const page_width = Math.max(watermark_hook_element.scrollWidth, watermark_hook_element.clientWidth)
  /* 获取页面最大长度*/
  // var page_height = Math.max(watermark_hook_element.scrollHeight,watermark_hook_element.clientHeight,document.documentElement.clientHeight)-defaultSettings.watermark_height/2;
  const page_height = Math.max(watermark_hook_element.scrollHeight, watermark_hook_element.clientHeight)
  
  const setting = arguments[0] || {}
  const parentEle = watermark_hook_element

  let page_offsetTop = 0
  let page_offsetLeft = 0
  if (setting.watermark_parent_width || setting.watermark_parent_height) {
    /* 指定父元素同时指定了宽或高*/
    if (parentEle) {
      page_offsetTop = parentEle.offsetTop || 0
      page_offsetLeft = parentEle.offsetLeft || 0
      settings.watermark_x = settings.watermark_x + page_offsetLeft
      settings.watermark_y = settings.watermark_y + page_offsetTop
    }
  } else {
    if (parentEle) {
      page_offsetTop = parentEle.offsetTop || 0
      page_offsetLeft = parentEle.offsetLeft || 0
    }
  }

  /* 创建水印外壳div*/
  let otdiv = document.getElementById(settings.watermark_id)
  let shadowRoot = null

  if (!otdiv) {
    otdiv = document.createElement('div')
    /* 创建shadow dom*/
    otdiv.id = settings.watermark_id
    otdiv.setAttribute('style', 'pointer-events: none !important; display: block !important')
    /* 判断浏览器是否支持attachShadow方法*/
    if (typeof otdiv.attachShadow === 'function') {
      /* createShadowRoot Deprecated. Not for use in new websites. Use attachShadow*/
      shadowRoot = otdiv.attachShadow({mode: 'open'})
    } else {
      shadowRoot = otdiv
    }
    /* 将shadow dom随机插入body内的任意位置*/
    const nodeList = watermark_hook_element.children
    const index = Math.floor(Math.random() * (nodeList.length - 1))
    if (nodeList[index]) {
      watermark_hook_element.insertBefore(otdiv, nodeList[index])
    } else {
      watermark_hook_element.appendChild(otdiv)
    }
  } else if (otdiv.shadowRoot) {
    shadowRoot = otdiv.shadowRoot
  }
  /* 三种情况下会重新计算水印列数和x方向水印间隔：1、水印列数设置为0，2、水印宽度大于页面宽度，3、水印宽度小于于页面宽度*/
  settings.watermark_cols = parseInt((page_width - settings.watermark_x) / (settings.watermark_width + settings.watermark_x_space)) + 1
  const temp_watermark_x_space = parseInt((page_width - settings.watermark_x - settings.watermark_width * settings.watermark_cols) / (settings.watermark_cols))
  settings.watermark_x_space = temp_watermark_x_space ? settings.watermark_x_space : temp_watermark_x_space
  let allWatermarkWidth

  /* 三种情况下会重新计算水印行数和y方向水印间隔：1、水印行数设置为0，2、水印长度大于页面长度，3、水印长度小于于页面长度*/
  settings.watermark_rows = parseInt((page_height - settings.watermark_y) / (settings.watermark_height + settings.watermark_y_space)) + 1
  const temp_watermark_y_space = parseInt((page_height - settings.watermark_y - settings.watermark_height * settings.watermark_rows) / (settings.watermark_rows))
  settings.watermark_y_space = temp_watermark_y_space ? settings.watermark_y_space : temp_watermark_y_space
  let allWatermarkHeight

  if (settings.watermark_parent_element) {
    allWatermarkWidth = settings.watermark_x + settings.watermark_width * settings.watermark_cols + settings.watermark_x_space * (settings.watermark_cols - 1)
    allWatermarkHeight = settings.watermark_y + settings.watermark_height * settings.watermark_rows + settings.watermark_y_space * (settings.watermark_rows - 1)

  } else {
    allWatermarkWidth = page_offsetLeft + settings.watermark_x + settings.watermark_width * settings.watermark_cols + settings.watermark_x_space * (settings.watermark_cols - 1)
    allWatermarkHeight = page_offsetTop + settings.watermark_y + settings.watermark_height * settings.watermark_rows + settings.watermark_y_space * (settings.watermark_rows - 1)
  }

  let x
  let y
  for (let i = 0; i < settings.watermark_rows; i++) {
    if (settings.watermark_parent_element) {
      y = page_offsetTop + settings.watermark_y + (settings.watermark_y_space + settings.watermark_height) * i
    } else {
      y = settings.watermark_y + (page_height - allWatermarkHeight) / 2 + (settings.watermark_y_space + settings.watermark_height) * i
    }
    for (let j = 0; j < settings.watermark_cols; j++) {
      if (settings.watermark_parent_element) {
        x = page_offsetLeft + settings.watermark_x + (page_width - allWatermarkWidth) / 2 + (settings.watermark_width + settings.watermark_x_space) * j
      } else {
        x = settings.watermark_x + (page_width - allWatermarkWidth) / 2 + (settings.watermark_width + settings.watermark_x_space) * j
      }
      const mask_div = document.createElement('div')
      const oText = document.createTextNode(settings.watermark_txt)
      mask_div.appendChild(oText)
      /* 设置水印相关属性start*/
      mask_div.id = settings.watermark_prefix + i + j
      /* 设置水印div倾斜显示*/
      mask_div.style.webkitTransform = `rotate(-${  settings.watermark_angle  }deg)`
      mask_div.style.MozTransform = `rotate(-${  settings.watermark_angle  }deg)`
      mask_div.style.msTransform = `rotate(-${  settings.watermark_angle  }deg)`
      mask_div.style.OTransform = `rotate(-${  settings.watermark_angle  }deg)`
      mask_div.style.transform = `rotate(-${  settings.watermark_angle  }deg)`
      mask_div.style.visibility = ''
      mask_div.style.position = 'absolute'
      /* 选不中*/
      mask_div.style.left = `${x  }px`
      mask_div.style.top = `${y  }px`
      mask_div.style.overflow = 'hidden'
      mask_div.style.zIndex = '9999999'
      mask_div.style.opacity = settings.watermark_alpha
      mask_div.style.fontSize = settings.watermark_fontsize
      mask_div.style.fontFamily = settings.watermark_font
      mask_div.style.color = settings.watermark_color
      mask_div.style.textAlign = 'center'
      mask_div.style.width = `${settings.watermark_width  }px`
      mask_div.style.height = `${settings.watermark_height  }px`
      mask_div.style.display = 'block'
      mask_div.style['-ms-user-select'] = 'none'
      /* 设置水印相关属性end*/
      shadowRoot.appendChild(mask_div)
    }
  }

  settings.watermarkDom = settings.watermarkDom ? settings.watermarkDom : hasObserver ? new MutationObserver(settings.domChangeCallback) : null
  // monitor 是否监控， true: 不可删除水印; false: 可删水印。
  if (settings.monitor && hasObserver) {
    const option = {
      subtree: true,
      childList: true,
      attributes: true,
      characterData: true,
      'attributeFilter': ['style']
    }
    settings.watermarkDom.observe(watermark_hook_element, option)
    settings.watermarkDom.observe(otdiv.shadowRoot, option)
  }
}

/* 手动移除水印*/
watermark.remove = function({watermark_id}) {
  const [removeMark] = watermark.marks.filter(item => item.watermark_id === watermark_id)
  if (!removeMark) return
  // remove() 方法里用 MutationObserver 的 disconnect() 解除监听即可
  removeMark.watermarkDom && removeMark.watermarkDom.disconnect()
  removeMark.watermark_parent_element && document.getElementById(removeMark.watermark_id) && removeMark.watermark_parent_element.removeChild(document.getElementById(removeMark.watermark_id))
  watermark.marks = watermark.marks.filter(item => item.watermark_id !== watermark_id)
}

export default watermark
