<template>
  <div class="draggable-item-html" v-html="i18nValue(relationData, item.placeholder)" />
</template>

<script>
import { isEmpty } from 'lodash-es'
import {
  getObjectValue,
  keysMap
} from '@/components/DataChart/analyze.js'

export default {
  name: 'DraggableItemHtml',
  props: {
    item: Object,
    relationData: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {}
  },
  mounted() {
    const imgAll = this.$el.querySelectorAll('img')
    imgAll.forEach(item => {
      item.setAttribute('crossorigin', 'anonymous')
    })
  },
  methods: {
    i18nValue(data, str) {
      // 匹配替换 ##{{some_text}}## 中 some_text
      const regex = /#\*{(.*?)}\*#/g
      const replacedString = str.replace(regex, match => {
        let sourceStr = decodeURIComponent(match).slice(2, -2)
        if (!data || isEmpty(data.data) || !sourceStr) {
          // 如果没有数据, 则
          return '___'
        }

        let dealJson = JSON.parse(sourceStr)

        let targetDeal = getObjectValue(keysMap, dealJson.method)

        // 判断是否有处理函数, 如果有, 则调用处理函数, 否则直接返回处理后的数据
        let srt = ''
        if (targetDeal) {
          // targetDeal 判断是否是函数
          if (Object.prototype.toString.call(targetDeal) === '[object Function]') {
            srt = targetDeal({
              sourceData: data.data.filter(item => item[dealJson.source]),
              dealJson
            })
          }
          if (targetDeal.handler) {
            srt = targetDeal.handler({ sourceData: data.data.filter(item => item[dealJson.source]), dealJson })
          }
        }
        // let srt = targetDeal.handler({
        //   sourceData: data.data.filter(item => item[dealJson.source]),
        //   dealJson
        // })
        // 这里可以进行根据匹配内容的不同进行替换逻辑
        return srt
      })
      return replacedString
    }
  }
}
</script>

<style lang="scss" scoped>
.draggable-item-html {
  -moz-user-select: none; /* 火狐 */
  -webkit-user-select: none; /* webkit浏览器 */
  -ms-user-select: none; /* IE10 */
  -khtml-user-select: none; /* 早期浏览器 */
  user-select: none;
}
</style>
