
// 根据路径获取对象的值
export function getObjectValue(obj, pathArr) {
  return pathArr.reduce((acc, key) => (acc ? acc[key] : undefined), obj)
}

// 谁 是什么 的分析
export const keysMap = {
  '描述性分析': {
    '平均值': {
      handler: ({ sourceData, dealJson }) => {
        const { dealKey } = dealJson
        const sortedData = sourceData.sort((a, b) => b[dealKey] - a[dealKey])
        const average = (sortedData.reduce((a, b) => a + Number(b[dealKey]), 0) / sortedData.length).toFixed(2)
        return average
      }
    },
    '中位数': {
      dealOptSchema: [{
        label: '目标值类型',
        cascaderProps: { value: 'label', label: 'label' },
        select: [{
          label: '值'
        }, {
          label: '名字'
        }],
        interactionType: 'select'
      }],
      handler: ({ sourceData, dealJson }) => {
        const { dealKey, source, dealOpt } = dealJson
        let targetKeyPath = dealOpt['目标值类型']?.keyPath
        const sortedData = sourceData.sort((a, b) => Number(b[dealKey]) - Number(a[dealKey]))
        return sortedData[Math.floor(sortedData.length / 2)][targetKeyPath === '值' ? dealKey : targetKeyPath === '名字' ? source : '']
      }
    },
    '排序': {
      dealOptSchema: [
        {
          label: '排序方式',
          cascaderProps: { value: 'label', label: 'label' },
          select: [{
            label: '默认',
            children: [{
              label: '第几名',
              valueVerify: {
                type: 'number',
                default: 1
              }
            }, {
              label: '范围',
              valueVerify: {
                type: 'number[]',
                default: [1, 2]
              }
            }]
          }, {
            label: '升序',
            children: [{
              label: '第几名',
              valueVerify: {
                type: 'number',
                default: 1
              }
            }, {
              label: '范围',
              valueVerify: {
                type: 'number[]',
                default: [1, 2]
              }
            }]
          }, {
            label: '降序',
            children: [{
              label: '第几名',
              valueVerify: {
                type: 'number',
                default: 1
              }
            }, {
              label: '范围',
              valueVerify: {
                type: 'number[]',
                default: [1, 2]
              }
            }]
          }],
          interactionType: 'input'
        }, {
          label: '目标值类型',
          cascaderProps: { value: 'label', label: 'label' },
          select: [{
            label: '值'
          }, {
            label: '占比'
          }, {
            label: '名字'
          }],
          interactionType: 'select'
        }
      ],
      handler: ({ sourceData, dealJson }) => {
        const { dealKey, source, dealOpt } = dealJson

        let formatStr = ''
        let targetKeyPath = dealOpt['目标值类型'].keyPath
        // 根据value排序，target为第几名
        let sortedData = []
        if (dealOpt['排序方式'].keyPath.includes('默认')) {
          sortedData = sourceData
        } else {
          sortedData = sourceData.sort((a, b) => {
            if (dealOpt['排序方式'].keyPath.includes('升序')) {
              return a[dealKey] - b[dealKey]
            } else if (dealOpt['排序方式'].keyPath.includes('降序')) {
              return b[dealKey] - a[dealKey]
            }
          })
        }

        if (dealOpt['排序方式'].keyPath.includes('第几名')) {
          const target = sortedData[dealOpt['排序方式'].value - 1]
          // 处理目标值key
          let formatStrKey = targetKeyPath === '值' ? dealKey : targetKeyPath === '名字' ? source : targetKeyPath === '占比' ? dealKey : ''

          // 处理目标值
          if (dealOpt['目标值类型'].keyPath.includes('占比')) {
            const sum = sortedData.reduce((a, b) => a + Number(b[formatStrKey]), 0)
            formatStr = `${(target[formatStrKey] / sum * 100).toFixed(2)}%`
          } else {
            formatStr = target[formatStrKey]
          }
          // formatStr =  target[targetKeyPath === '值' ? dealKey : targetKeyPath === '名字' ? source : '']
        } else  if (dealOpt['排序方式'].keyPath.includes('范围')) {
          const target = sortedData.slice(dealOpt['排序方式'].value[0] - 1, dealOpt['排序方式'].value[1])
          formatStr = target.map(item => item[targetKeyPath === '值' ? dealKey : targetKeyPath === '名字' ? source : '']).join('、').replace(/、([^、]+)$/, '和$1')
        }

        return formatStr
      }
    }
  },
  '趋势分析': {
    '整体趋势': {
      handler: ({ sourceData, dealJson }) => {
        let data = sourceData.map(item => item[dealJson.dealKey])
        const length = data.length

        // 计算线性回归
        let sumX = 0
        let sumY = 0
        let sumXY = 0
        let sumX2 = 0

        for (let i = 0; i < length; i++) {
          sumX += i
          sumY += data[i]
          sumXY += i * data[i]
          sumX2 += i * i
        }

        const slope = (length * sumXY - sumX * sumY) / (length * sumX2 - sumX * sumX)

        // 判断趋势类型
        if (slope > 0) {
          return '增长'
        } else if (slope < 0) {
          return '下降'
        } else {
          return '平稳'
        }
      }
    }
  //   '分段趋势': ({ sourceData, dealJson }) => {
  //     // 分析数据的趋势分段分析，分为上升、下降、平稳， 例如：某一段时间内，数据整体上升，但是在某一段时间内，数据下降，这段时间内的数据就是分段趋势
  //     const { dealKey, source } = dealJson
  //     const sortedData = sourceData.sort((a, b) => b[dealKey] - a[dealKey])
  //     const trend = []
  //     for (let i = 0; i < sortedData.length - 1; i++) {
  //       const firstValue = sortedData[i][source]
  //       const lastValue = sortedData[i + 1][source]
  //       const changePercentage = ((lastValue - firstValue) / firstValue * 100).toFixed(2)
  //       if (changePercentage > 0) {
  //         trend.push('上升')
  //       } else if (changePercentage < 0) {
  //         trend.push('下降')
  //       } else {
  //         trend.push('平稳')
  //       }
  //     }
  //     const trendMap = {
  //       '上升': 0,
  //       '下降': 0,
  //       '平稳': 0
  //     }
  //     trend.forEach(item => {
  //       trendMap[item]++
  //     })
  //     const trendArr = Object.entries(trendMap).sort((a, b) => b[1] - a[1])
  //     return `数据分段趋势为${trendArr[0][0]}。`
  //   }
  }
  // '比例分析': {
  //   '占比': ({ sourceData, dealJson }) => {
  //     const { dealKey } = dealJson
  //     const sortedData = sourceData.sort((a, b) => b[dealKey] - a[dealKey])
  //     const sum = sortedData.reduce((a, b) => a + b[dealKey], 0)
  //     const percentage = (sortedData[0][dealKey] / sum * 100).toFixed(2)
  //     return `${percentage}%。`
  //   }
  // '环比占比': {
  //   handler: chartOptions => {
  //     const data = chartOptions.series[0].data
  //     const sum = data.reduce((a, b) => a + b)
  //     const percentage = (data[0] / sum * 100).toFixed(2)
  //     return `数据环比占比为${percentage}%。`
  //   }
  // },
  // '同比占比': chartOptions => {
  //   const data = chartOptions.series[0].data
  //   const sum = data.reduce((a, b) => a + b)
  //   const percentage = (data[0] / sum * 100).toFixed(2)
  //   return `数据同比占比为${percentage}%。`
  // }
  // }
  // '对比分析': {
  //   '分组对比': {
  //     dealOptSchema: [
  //       {
  //         label: '排序方式',
  //         cascaderProps: { value: 'label', label: 'label' },
  //         select: [{
  //           label: '默认',
  //           children: [{
  //             label: '第几名',
  //             valueVerify: {
  //               type: 'number',
  //               default: 1
  //             }
  //           }, {
  //             label: '范围',
  //             valueVerify: {
  //               type: 'number[]',
  //               default: [1, 2]
  //             }
  //           }]
  //         }, {
  //           label: '升序',
  //           children: [{
  //             label: '第几名',
  //             valueVerify: {
  //               type: 'number',
  //               default: 1
  //             }
  //           }, {
  //             label: '范围',
  //             valueVerify: {
  //               type: 'number[]',
  //               default: [1, 2]
  //             }
  //           }]
  //         }, {
  //           label: '降序',
  //           children: [{
  //             label: '第几名',
  //             valueVerify: {
  //               type: 'number',
  //               default: 1
  //             }
  //           }, {
  //             label: '范围',
  //             valueVerify: {
  //               type: 'number[]',
  //               default: [1, 2]
  //             }
  //           }]
  //         }],
  //         interactionType: 'input'
  //       }, {
  //         label: '目标值类型',
  //         cascaderProps: { value: 'label', label: 'label' },
  //         select: [{
  //           label: '值'
  //         }, {
  //           label: '名字'
  //         }],
  //         interactionType: 'select'
  //       }
  //     ],
  //     handler({sourceData, dealJson}) {
  //       console.log('sourceData, dealJson: ', sourceData, dealJson)

  //     }
  //   },
  //   '环比对比': chartOptions => {
  //     const data = chartOptions.series[0].data
  //     const firstValue = data[0]
  //     const lastValue = data[data.length - 1]
  //     const trend = lastValue > firstValue ? '上升' : '下降'
  //     const changePercentage = ((lastValue - firstValue) / firstValue * 100).toFixed(2)
  //     return `数据环比${trend}，变化率为${changePercentage}%。`
  //   },
  //   '同比对比': chartOptions => {
  //     const data = chartOptions.series[0].data
  //     const firstValue = data[0]
  //     const lastValue = data[data.length - 1]
  //     const trend = lastValue > firstValue ? '上升' : '下降'
  //     const changePercentage = ((lastValue - firstValue) / firstValue * 100).toFixed(2)
  //     return `数据同比${trend}，变化率为${changePercentage}%。`
  //   },
  //   '最大值对比': chartOptions => {
  //     const data = chartOptions.series[0].data
  //     const max = Math.max(...data)
  //     return `数据最大值为${max}。`
  //   },
  //   '最小值对比': chartOptions => {
  //     const data = chartOptions.series[0].data
  //     const min = Math.min(...data)
  //     return `数据最小值为${min}。`
  //   },
  //   '平均值对比': chartOptions => {
  //     const data = chartOptions.series[0].data
  //     const average = (data.reduce((a, b) => a + b) / data.length).toFixed(2)
  //     return `数据平均值为${average}。`
  //   },
  //   '中位数对比': ({sourceData, dealJson}) => {
  //     const { dealKey, source } = dealJson
  //     const sortedData = sourceData.sort((a, b) => b[dealKey] - a[dealKey])
  //     return sortedData[Math.floor(sortedData.length / 2)][source]
  //   },
  //   '众数对比': chartOptions => {
  //     const data = chartOptions.series[0].data
  //     const mode = data.sort((a, b) =>
  //       data.filter(v => v === a).length - data.filter(v => v === b).length
  //     ).pop()
  //     return `数据众数为${mode}。`
  //   },
  //   '标准差对比': chartOptions => {
  //     const data = chartOptions.series[0].data
  //     const average = (data.reduce((a, b) => a + b) / data.length).toFixed(2)
  //     const standardDeviation = Math.sqrt(data.reduce((a, b) => a + (b - average) ** 2) / data.length).toFixed(2)
  //     return `数据标准差为${standardDeviation}。`
  //   },
  //   '方差对比': chartOptions => {
  //     const data = chartOptions.series[0].data
  //     const average = (data.reduce((a, b) => a + b) / data.length).toFixed(2)
  //     const variance = data.reduce((a, b) => a + (b - average) ** 2) / data.length
  //     return `数据方差为${variance}。`
  //   },
  //   '偏度对比': chartOptions => {
  //     const data = chartOptions.series[0].data
  //     const average = (data.reduce((a, b) => a + b) / data.length).toFixed(2)
  //     const standardDeviation = Math.sqrt(data.reduce((a, b) => a + (b - average) ** 2) / data.length).toFixed(2)
  //     const skewness = data.reduce((a, b) => a + ((b - average) / standardDeviation) ** 3) / data.length
  //     return `数据偏度为${skewness}。`
  //   },
  //   '峰度对比': chartOptions => {
  //     const data = chartOptions.series[0].data
  //     const average = (data.reduce((a, b) => a + b) / data.length).toFixed(2)
  //     const standardDeviation = Math.sqrt(data.reduce((a, b) => a + (b - average) ** 2) / data.length).toFixed(2)
  //     const kurtosis = data.reduce((a, b) => a + ((b - average) / standardDeviation) ** 4) / data.length
  //     return `数据峰度为${kurtosis}。`
  //   },
  //   '波动率对比': chartOptions => {
  //     const data = chartOptions.series[0].data
  //     const average = (data.reduce((a, b) => a + b) / data.length).toFixed(2)
  //     const standardDeviation = Math.sqrt(data.reduce((a, b) => a + (b - average) ** 2) / data.length).toFixed(2)
  //     const volatility = (standardDeviation / average * 100).toFixed(2)
  //     return `数据波动率为${volatility}%。`
  //   },
  //   '变异系数对比': chartOptions => {
  //     const data = chartOptions.series[0].data
  //     const average = (data.reduce((a, b) => a + b) / data.length).toFixed(2)

  //     const standardDeviation = Math.sqrt(data.reduce((a, b) => a + (b - average) ** 2) / data.length).toFixed(2)

  //     const coefficientOfVariation = (standardDeviation / average * 100).toFixed(2)
  //     return `数据变异系数为${coefficientOfVariation}%。`
  //   },
  //   '数据分布对比': chartOptions => {
  //     const data = chartOptions.series[0].data
  //     const average = (data.reduce((a, b) => a + b) / data.length).toFixed(2)

  //     const standardDeviation = Math.sqrt(data.reduce((a, b) => a + (b - average) ** 2) / data.length).toFixed(2)

  //     const coefficientOfVariation = (standardDeviation / average * 100).toFixed(2)

  //     const mode = data.sort((a, b) =>
  //       data.filter(v => v === a).length - data.filter(v => v === b).length
  //     ).pop()

  //     const median = data[Math.floor(data.length / 2)]

  //     const max = Math.max(...data)

  //     const min = Math.min(...data)
  //     return `数据平均值为${average}，标准差为${standardDeviation}，变异系数为${coefficientOfVariation}%，众数为${mode}，中位数为${median}，最大值为${max}，最小值为${min}。`
  //   }
  // },
  // '相关分析': {
  //   handler: ({sourceData, dealJson}) => {
  //     console.log('sourceData, dealJson: ', sourceData, dealJson)

  //     function calculatePearsonCorrelation(dataX, dataY) {
  //       if (dataX.length !== dataY.length) {
  //         throw new Error('数据长度不一致，无法计算皮尔逊相关系数。')
  //       }

  //       const n = dataX.length

  //       // 计算平均值
  //       const meanX = dataX.reduce((sum, value) => sum + value, 0) / n
  //       const meanY = dataY.reduce((sum, value) => sum + value, 0) / n

  //       // 计算 xi - x̄ 和 yi - ȳ 的数组
  //       const diffX = dataX.map(x => x - meanX)
  //       const diffY = dataY.map(y => y - meanY)

  //       // 计算 xi - x̄ 和 yi - ȳ 的平方和
  //       const sumDiffX = diffX.reduce((sum, value) => sum + value ** 2, 0)
  //       const sumDiffY = diffY.reduce((sum, value) => sum + value ** 2, 0)

  //       // 计算 xi - x̄ 和 yi - ȳ 的乘积和
  //       const sumDiffXY = diffX.reduce((sum, value, index) => sum + value * diffY[index], 0)

  //       // 计算皮尔逊相关系数
  //       const correlation = sumDiffXY / Math.sqrt(sumDiffX * sumDiffY)

  //       return correlation
  //     }
  //     function determineCorrelation(dataX, dataY) {
  //       const correlation = calculatePearsonCorrelation(dataX, dataY)

  //       if (correlation > 0.7) {
  //         return '强正相关'
  //       } else if (correlation > 0.3) {
  //         return '较弱正相关'
  //       } else if (correlation > -0.3) {
  //         return '无线性相关性'
  //       } else if (correlation > -0.7) {
  //         return '较弱负相关'
  //       } else {
  //         return '强负相关'
  //       }
  //     }
  //     let dataX = []
  //     let dataY = []
  //     const correlation = determineCorrelation(dataX, dataY)
  //     console.log('两个变量之间的关系：', correlation)
  //     return correlation
  //   }
  // }
  // '假设探索性分析': {},
  // '机器学习和数据挖掘': {}
}
