<template>
  <divider>
    <div id="layout-wrap" class="layout-wrap">
      <div class="aside" :style="`left: ${showSideBar ? '0' : '-300px'}`">
        <el-button
          class="icon-right"
          type="primary"
          :icon="showSideBar ? 'el-icon-d-arrow-left' : 'el-icon-d-arrow-right'"
          @click="showSideBar = !showSideBar"
        />
        <el-tabs v-model="activeName" class="el-tabs" type="border-card">
          <el-tab-pane label="报表" name="first">
            <el-input v-model="keywords" placeholder="请输入报表名称" />
            <div
              v-for="item in filterClassifyList"
              :key="item.smartModelId"
              :title="item.placeholder"
              class="item"
              draggable="true"
              unselectable="on"
              @drag="drag(item)"
              @dragend="dragend(item)"
            >
              {{ item.placeholder }}
            </div>
            <el-empty v-if="filterClassifyList.length === 0" />
          </el-tab-pane>
        </el-tabs>
      </div>
      <div id="content" class="page-wrap" @click="rightMenu.show = false">
        <GridLayout
          ref="gridlayout"
          :layout.sync="layout"
          :col-num="24"
          :row-height="15"
          :is-draggable="true"
          :is-resizable="true"
          :is-mirrored="false"
          :vertical-compact="true"
          :use-style-cursor="false"
          :margin="[8, 8]"
          :use-css-transforms="true"
        >
          <div
            v-if="Object.keys(activeItem).length && rightMenu.show"
            class="right-menu"
            :style="rightMenu.style"
            @click="rightMenu.show = false"
          >
            <slot name="rightMenu" />
          </div>
          <GridItem
            v-for="item in layout"
            :key="item.i"
            class="draggable-item"
            :is-draggable="item.i === activeItem.i"
            :class="{ active: item.i === activeItem.i }"
            :x="item.x || 0"
            :y="item.y || 0"
            :w="item.w || 1"
            :h="item.h || 1"
            :i="item.i"
            @dblclick.native="dblclick(item)"
          >
            <div
              class="item-wrap"
              :class="{ html: item.placeholderType === 2 || item.placeholderType === 4 || item.placeholderType === 1 }"
              :data-id="item.i"
              @click.right.prevent.stop="onActive"
            >
              <slot name="dargItem" :item="item" />
            </div>
          </GridItem>
        </GridLayout>
      </div>
      <!-- 侧边抽屉 -->
      <el-drawer
        class="drawer"
        :close-on-press-escape="false"
        size="40%"
        :show-close="false"
        :with-header="false"
        :visible.sync="drawerEditVisible"
        direction="rtl"
      >
        <div class="wrap">
          <div class="context-form">
            <slot name="drawer" :visible="drawerEditVisible" />
          </div>
          <div class="drawer__footer" style="text-align: left;">
            <el-button @click="() => (drawerEditVisible = false)">取消</el-button>
            <el-button type="primary" @click="onSaveRichText">保存</el-button>
          </div>
        </div>
      </el-drawer>
    </div>
  </divider>
</template>

<script>
import mark from './mark'
import { GridLayout, GridItem } from 'vue-grid-layout'
import Divider from './divider.vue'
import { cloneDeep } from 'lodash-es'
import { showTypeText } from '../utils.js'
import { getEmail, queryPlaceholder } from '@/apis/reportService.js'

const mouseXY = { x: null, y: null }
const DragPos = { x: null, y: null, w: 12, h: 18, i: null }
export default {
  name: 'DargLayout',
  components: {
    GridLayout,
    GridItem,
    Divider
  },
  props: {
    value: {
      type: Array,
      default: Array
    },
    visible: {
      type: Boolean,
      default: false
    },
    activeItemProp: {
      type: Object,
      default: Object
    },
    reportForm: {
      type: Object,
      default: Object
    }
  },
  data() {
    return {
      showSideBar: false,
      activeName: 'first',
      classifyList: [],
      rightMenu: {
        show: false,
        style: {}
      },
      keywords: ''
    }
  },
  computed: {
    filterClassifyList() {
      let arr = this.layout.filter(item => item.placeholderType === 3)
      return this.classifyList.filter(item => !arr.some(layoutItem => layoutItem.smartModelId === item.smartModelId) && item.placeholder.includes(this.keywords))
    },
    layout: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    drawerEditVisible: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    },
    activeItem: {
      get() {
        return this.activeItemProp
      },
      set(val) {
        this.$emit('update:activeItemProp', val)
      }
    },
    showTypeText
  },
  mounted() {
    document.addEventListener(
      'dragover',
      function(e) {
        mouseXY.x = e.clientX
        mouseXY.y = e.clientY
      },
      false
    )

    this.getClassifyList().finally(() => {
      // 设置水印
      getEmail().then(res => {
        mark.add({
          watermark_id: 'content1',
          watermark_txt: res || '杭州六倍体科技有限公司',
          watermark_parent_node: 'content',
          watermark_fontsize: '18px',
          watermark_alpha: 0.03,
          watermark_color: '#ffff99',
          watermark_width: 350,
          throttleTime: 1000,
          watermark_height: 150,
          watermark_x_space: 10,
          watermark_y_space: 10,
          watermark_x: -40,
          watermark_y: 90
        })
        mark.add({
          watermark_id: 'content2',
          watermark_txt: res || '杭州六倍体科技有限公司',
          watermark_parent_node: 'content',
          watermark_fontsize: '18px',
          watermark_alpha: 0.15,
          watermark_color: '#999',
          throttleTime: 1000,
          watermark_width: 350,
          watermark_height: 150,
          watermark_x_space: 10,
          watermark_y_space: 10,
          watermark_x: -40,
          watermark_y: 20
        })
      })
    })
  },
  beforeDestroy() {
    mark.remove({ watermark_id: 'content1' })
    mark.remove({ watermark_id: 'content2' })
  },
  methods: {
    async getClassifyList() {
      try {
        this.addOrEditLoading = true
        const params = {
          pageSize: 9999
        }
        const res = await this.$axios.get(this.$API.getSmartReportFormList, {params})
        this.classifyList = res.data.map(item => {
          const obj = {
            w: 12,
            h: 18,
            placeholderType: 3,
            placeholder: item.modelName,
            smartModelId: item.modelId,
            showType: item.showType,
            reportConditionItem: cloneDeep(this.reportForm),
            data: {
              modelName: item.modelName
            }
          }
          delete obj.reportConditionItem.reportAndModelDetailList
          return obj
        })
        this.addOrEditLoading = false
      } catch (error) {
        this.addOrEditLoading = false
        console.log(error)
      }
    },
    // todo
    // 修改内容后保存并更新视图
    async onSaveRichText() {
      this.$parent.validate().then(() => {
        this.layout = this.layout.map(item => {
          if (item.i === this.activeItem.i) {
            if (this.$parent.$refs.tinymceEdit) {
              this.activeItem.placeholder = this.$parent.$refs.tinymceEdit.getSourceValue()
            }
            return this.activeItem
          }
          return item
        })
        this.$refs['gridlayout'].layoutUpdate()
        this.drawerEditVisible = false
        this.activeItem.loading = true
        this.$parent.updateChartItem(this.activeItem, 'update')
      })
    },
    // 双击选中
    dblclick(row) {
      if (this.activeItem.i === row.i) {
        this.activeItem = {}
      } else {
        this.activeItem = row
      }
    },
    // 选中
    onActive(e) {
      const {
        pageX,
        pageY,
        currentTarget: {
          dataset: { id }
        }
      } = e
      if (!id) return
      const parentNode = document.querySelector('#content')
      const { clientWidth, clientHeight, offsetLeft, offsetTop, offsetParent } = parentNode
      const [row] = this.layout.filter(item => item.i === id)
      let obj = {
        offsetParent,
        offsetLeft,
        offsetTop
      }
      let tLeft = 0
      let tTop = 0
      while (obj.offsetParent) {
        tLeft += obj.offsetLeft
        tTop += obj.offsetTop
        obj = obj.offsetParent
      }
      const mouseLeft = pageX - tLeft
      const mouseTop = pageY - tTop
      this.activeItem = cloneDeep(row)
      this.rightMenu = {
        id: row.i,
        show: true,
        style: {
          left: `${mouseLeft}px`,
          top: `${mouseTop}px`
        }
      }
      // 处理编辑情况
      if (clientWidth - mouseLeft < 200) {
        this.rightMenu.style.left = null
        this.rightMenu.style.right = '0px'
      }
      if (clientHeight - mouseTop < 142) {
        this.rightMenu.style.top = null
        this.rightMenu.style.bottom = '0px'
      }
    },
    drag(row) {
      const parentRect = document.getElementById('content').getBoundingClientRect()
      let mouseInGrid = false
      if (mouseXY.x > parentRect.left && mouseXY.x < parentRect.right && mouseXY.y > parentRect.top && mouseXY.y < parentRect.bottom) {
        mouseInGrid = true
      }
      if (mouseInGrid === true && this.layout.findIndex(item => item.i === 'drop') === -1) {
        this.layout.push({
          x: (this.layout.length * 2) % 24,
          y: this.layout.length + 24, // puts it at the bottom
          w: row.w,
          h: row.h,
          i: 'drop'
        })
      }
      const index = this.layout.findIndex(item => item.i === 'drop')
      if (index !== -1) {
        try {
          this.$refs.gridlayout.$children[this.layout.length].$refs.item.style.display = 'none'
        } catch {
          console.log(12)
        }
        const el = this.$refs.gridlayout.$children[index]
        el.dragging = { top: mouseXY.y - parentRect.top, left: mouseXY.x - parentRect.left }
        const new_pos = el.calcXY(mouseXY.y - parentRect.top, mouseXY.x - parentRect.left)
        if (mouseInGrid === true) {
          this.$refs.gridlayout && this.$refs.gridlayout.dragEvent('dragstart', 'drop', new_pos.x, new_pos.y, row.h, row.w)
          DragPos.i = String(index)
          DragPos.x = this.layout[index].x
          DragPos.y = this.layout[index].y
        }
        if (mouseInGrid === false) {
          this.$refs.gridlayout && this.$refs.gridlayout.dragEvent('dragend', 'drop', new_pos.x, new_pos.y, row.h, row.w)
          this.layout = this.layout.filter(obj => obj.i !== 'drop')
        }
      }
    },
    dragend(row) {
      const parentRect = document.getElementById('content').getBoundingClientRect()
      let mouseInGrid = false
      if (mouseXY.x > parentRect.left && mouseXY.x < parentRect.right && mouseXY.y > parentRect.top && mouseXY.y < parentRect.bottom) {
        mouseInGrid = true
      }
      if (mouseInGrid === true) {
        this.$refs.gridlayout && this.$refs.gridlayout.dragEvent('dragend', 'drop', DragPos.x, DragPos.y, row.h, row.w)
        const obj = {
          ...row,
          x: DragPos.x,
          y: DragPos.y,
          w: 12,
          h: 18,
          showType: '',
          i: row.smartModelId
        }
        this.activeItem = cloneDeep(obj)
        queryPlaceholder({ smartModelId: obj.smartModelId }).then(res => {
          obj.reportConditionItem.placeList = res || []
        })
        this.$parent.updateChartItem(obj)
        this.layout = this.layout.filter(obj => obj.i !== 'drop')
      }
    }
  }
}
</script>

<style scoped lang="scss">
.layout-wrap {
  display: flex;
  list-style: none;
  padding: 0;
  position: relative;
  .aside {
    width: 300px;
    top: 0;
    height: calc(100vh - 80px);
    position: fixed;
    z-index: 10;
    transition: all 0.2s ease;
    :deep(.el-tabs--border-card) {
      height: 100%;
      display: flex;
      flex-direction: column;
      .el-tabs__content {
        flex: 1;
        overflow: auto;
      }
    }
    .icon-right {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      position: absolute;
      top: 0;
      left: calc(100% - 40px);
      z-index: -1;
      transition: all 0.3s;
      &:hover {
        left: 100%;
      }
    }
    .item {
      padding: 5px;
      border-radius: 4px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      &:hover {
        background-color: #ccc;
        color: #fff;
      }
    }
  }
  .aside-placeholder {
    transition: all 0.2s ease;
  }
}
.page-wrap {
  flex: 1;
  position: relative;
  :deep(.vue-grid-item).vue-grid-placeholder {
    background-color: #5470c6;
  }
  .item-wrap {
    height: 100%;
    &.html {
      overflow: auto;
    }
    :deep(.data-chart) {
      border: 1px solid rgb(239, 239, 239);
      padding: 5px;
      box-sizing: border-box;
      overflow: hidden;
    }
  }
  .draggable-item {
    border-radius: 3px 0 3px 3px;
    :deep(.vue-resizable-handle) {
      display: none;
    }
    &.active {
      border-color: #cccc;
      cursor: move;
      z-index: 2;
      -webkit-box-shadow: 0 0.3px 0.9px rgba(0, 0, 0, 0.12), 0 1.6px 3.6px rgba(0, 0, 0, 0.16);
      box-shadow: 0 0.3px 0.9px rgba(0, 0, 0, 0.12), 0 1.6px 3.6px rgba(0, 0, 0, 0.16);
      -webkit-transform: translate3d(0, -2px, 0);
      transform: translate3d(0, -2px, 0);
      :deep(.vue-resizable-handle) {
        display: block;
      }
    }
  }
}
.drawer {
  :deep(.el-drawer__body) {
    padding: 10px;
    display: flex;
    flex-direction: column;
    .toop-tip {
      margin-bottom: 10px;
      font-size: 12px;
      color: red;
    }
    .editor {
      flex: 1;
      > div {
        height: 100%;
        position: relative;
        .w-e-text-container {
          width: 100%;
          height: calc(100% - 31px) !important;
          position: absolute;
          top: 31px;
          bottom: 0;
        }
      }
    }
  }
}
.wrap {
  height: 100%;
  display: flex;
  flex-direction: column;
  .context-form {
    flex: 1;
    :deep(.el-form) {
      display: flex;
      flex-direction: column;
      height: calc(100% - 14px);
      .editor {
        > div {
          min-height: 300px;
        }
      }
    }
  }
}
.drawer__footer {
  padding-top: 10px;
}
.right-menu {
  width: 200px;
  border: 1px solid #f4f4f4;

  --bg1: hsla(0, 0%, 98%, 0.9);
  --bg1a: hsla(0, 0%, 97%, 0.96);
  --bg2: #f0f0f0;

  -webkit-backdrop-filter: saturate(2) blur(20px);
  backdrop-filter: saturate(2) blur(20px);
  background: var(--bg1);
  border-radius: 8px;
  opacity: 1;
  position: absolute;
  transition: opacity 0.1s ease 0.3s;
  cursor: default;
  z-index: 3;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  .item {
    transition: all 0.3s;
    align-items: center;
    border-radius: 4px;
    box-sizing: border-box;
    color: var(--txt-col);
    display: flex;
    font-size: 12px;
    font-weight: 500;
    height: 28px;
    margin: 6px;
    padding: 4px 6px;
    &:hover {
      background: var(--bg2);
    }
  }
}
</style>
