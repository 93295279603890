<template>
  <div ref="divider" class="divider">
    <div
      v-for="item in totalLine"
      :key="item"
      :style="`top: ${item * ratioH}px`"
      class="divider-line"
    />
    <slot />
  </div>
</template>

<script>
import elementResizeDetectorMaker from 'element-resize-detector'
export default {
  name: 'Divider',
  data() {
    return {
      a4_width: 210,
      a4_height: 297,
      dividerStyle: {
        width: 0,
        height: 0
      }
    }
  },
  computed: {
    ratioH() {
      return Number(((this.dividerStyle?.width / this.a4_width) * this.a4_height).toFixed(4))
    },
    totalLine() {
      return Math.floor((this.dividerStyle.height / this.ratioH) || 0)
    }
  },
  mounted() {
    this.erd = elementResizeDetectorMaker()
    this.erd.listenTo(this.$el, element => {
      this.dividerStyle.width = element.offsetWidth
      this.dividerStyle.height = element.offsetHeight
    })
  },
  methods: {

  }
}
</script>

<style scoped lang="scss">
// scss
.divider {
  position: relative;
  .divider-line {
    position: absolute;
    z-index: 10;
    width: 100%;
    border-top: 3px dashed #ccc;
  }
}
</style>
