/*
 * @Author: your name
 * @Date: 2021-12-15 13:35:14
 * @LastEditTime: 2021-12-16 10:10:25
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /qxs-management/src/apis/chartService.js
 */
import request from '@/utils/request'
import API from './api-types'

export function getSmartReportFormList(params) {
  return request({
    method: 'get',
    url: API.getSmartReportFormList,
    params
  })
}

export function saveOrUpdateCategory(data) {
  return request({
    method: 'post',
    url: API.saveOrUpdateCategory,
    data
  })
}

export function getListCategory(params) {
  return request({
    method: 'get',
    url: API.getListCategory,
    params
  })
}

export function deleteCategory(params) {
  return request({
    method: 'post',
    url: API.deleteCategory,
    params
  })
}

// 删除报表
export function deleteSmartModel(params) {
  return request({
    method: 'post',
    url: API.deleteSmartModel,
    params
  })
}

export function getRequestSqlMemberList(params) {
  return request({
    method: 'get',
    url: API.getRequestSqlMemberList,
    params
  })
}

export function updateSmartModelStatus(params) {
  return request({
    method: 'post',
    url: API.updateSmartModelStatus,
    params
  })
}
export function getColumnValues(params) {
  return request({
    method: 'get',
    url: API.getColumnValues,
    params
  })
}

// 报告
export function reportPageList(params) {
  return request({
    method: 'get',
    url: API.reportPageList,
    params
  })
}

export function queryModelData(data) {
  return request({
    method: 'post',
    url: API.queryModelData,
    data
  })
}

export function saveOrUpdate(data) {
  return request({
    method: 'post',
    url: API.saveOrUpdate,
    data
  })
}
export function detail(params) {
  return request({
    method: 'get',
    url: API.detail,
    params
  })
}

export function updateStatus(params) {
  return request({
    method: 'post',
    url: API.updateStatus,
    params
  })
}

export function reportModelPageList(params) {
  return request({
    url: API.reportModelPageList,
    method: 'get',
    params
  })
}

export function reportModelDelete(params) {
  return request({
    url: API.reportModelDelete,
    method: 'post',
    params
  })
}

export function getOrgList(params) {
  return request({
    url: API.getOrgList,
    method: 'get',
    params
  })
}

export function getFullOrgList(data) {
  return request({
    url: API.getFullOrgList,
    method: 'post',
    data
  })
}
export function reportSaveOrUpdate(data) {
  return request({
    url: API.reportSaveOrUpdate,
    method: 'post',
    data
  })
}

export function queryPlaceholder(params) {
  return request({
    method: 'get',
    url: API.queryPlaceholder,
    params
  })
}

export function reportDelete(params) {
  return request({
    url: API.reportDelete,
    method: 'post',
    params
  })
}

export function findAllParentOrgIdList(params) {
  return request({
    url: API.findAllParentOrgIdList,
    method: 'get',
    params
  })
}

export function getEmail(params) {
  return request({
    url: API.getEmail,
    method: 'get',
    params
  })
}
